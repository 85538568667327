var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDialog',_vm._g(_vm._b({ref:"baseDialog",staticClass:"picUploadTableDialogComponent",attrs:{"width":_vm.$attrs.width || '80%'},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('LoadingBtn',{attrs:{"disabled":_vm.tableLoading,"type":"primary"},on:{"click":_vm.uploadHandler}},[_vm._v(" 上传")]),_c('LoadingBtn',{on:{"click":_vm.cancel}},[_vm._v(" 取消")])]},proxy:true}])},'BaseDialog',_vm.$attrs,false),_vm.$listeners),[[_c('CommonTable',{ref:"table",attrs:{"height":"auto","infoData":_vm.data,"cols":_vm.cols,"tableLoading":_vm.tableLoading},on:{"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('el-button',{staticClass:"empty-btn",attrs:{"type":"text","icon":"el-icon-plus"},on:{"click":_vm.uploadMorePic}},[_vm._v("添加图片")])]},proxy:true},{key:"pathSlot",fn:function(ref){
var row = ref.scoped;
var prop = ref.prop;
return [_c('CacheImg',{ref:"img",staticStyle:{"width":"80px","height":"80px"},attrs:{"raw":row.raw,"fit":"contain"}})]}},{key:"titleHeaderSlot",fn:function(){return [_c('div',{staticClass:"title"},[_vm._v("名称")])]},proxy:true},{key:"existSlot",fn:function(ref){
var scoped = ref.scoped;
return [(scoped[scoped.prop])?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(" 已存在，无需重复上传。")]):_c('el-tag',{attrs:{"type":"primary"}},[_vm._v(" 可上传")])]}},{key:"categoryNameHeaderSlot",fn:function(){return [_c('div',{staticClass:"title"},[_vm._v("图片分类")]),_c('div',{staticClass:"sub-title"},[_c('CateHead',{attrs:{"sup_this":_vm.cur_this},on:{"updateSelectedData":_vm.updateSelectedData}})],1)]},proxy:true},{key:"categoryNameSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('div',{staticClass:"selected-type"},[_vm._v(" "+_vm._s(scoped[scoped.prop])+" ")]),_c('div',{staticClass:"sub-title"},[_c('Cate',{attrs:{"data":scoped,"sup_this":_vm.cur_this},on:{"updateSelectedData":function($event){return _vm.updateSelectedData($event, _vm.data[scoped.index] || {})}}})],1)]}}])},[_vm._t("default",[_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [_c('LoadingBtn',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.del(scoped.$index)}}},[_vm._v("删除")])]}}])})])],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }